import { useEffect, useState } from 'react'
import { ImageBackground, Platform, Pressable, View } from 'react-native'
import { InfoButton } from '../../../Components/Buttons/Button'
import { Popup } from '../../../Components/Playlists/Popup'
import useFiles from '../../../Hooks/useFiles'
import CategoryCard from './CategoryCard'
import { Text } from 'react-native'

export default function CategoryMainDesign({ items, wasDataCached, navigate, deviceType }) {
	const width = '100%'

	const { getPhoto, isImage } = useFiles()

	const [imageBg, setImageBg] = useState(null)
	const fallbackBg = require('../../../assets/home-bg.png')
	const [modalVisible, setModalVisible] = useState(false)
	const [selectedBoxNumInfo, setSelectedBoxNumInfo] = useState(0)

	const setBoxNum = boxNum => {
		setSelectedBoxNumInfo(boxNum)
	}

	useEffect(() => {
		if (items.length > 0) {
			if (Platform.OS !== 'web' && wasDataCached) {
				if (isImage(items[0].Photo)) {
					setImageBg(getPhoto(items[0].Photo))
				} else {
					setImageBg(null)
				}
			} else {
				setImageBg(items[0].Photo)
			}
		}
	}, [])

	return (
		<View
			style={{ flex: 1 }}
			onResponderRelease={() => setBoxNum(0)}
			onStartShouldSetResponder={evt => true}
			onMoveShouldSetResponder={evt => true}
		>
			<View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
				{items.length > 2 && (
					<View
						style={{
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<View style={{ flexDirection: 'row' }}>
							<CategoryCard
								selectedBoxNumInfo={selectedBoxNumInfo}
								boxNumber={1}
								setBoxNum={setBoxNum}
								item={items[0]}
								wasDataCached={wasDataCached}
								navigate={navigate}
								deviceType={deviceType}
								style={{
									// height: 200,
									// width: 250,
									// borderColor: '#f39851',
									// borderWidth: 1,
									// borderRadius: 10,
									backgroundColor: '#f07e26',
									backgroundColorMain: '#3B312C'
									// position: 'absolute',
									// marginTop: -40,
								}}
							/>
							<CategoryCard
								selectedBoxNumInfo={selectedBoxNumInfo}
								boxNumber={2}
								setBoxNum={setBoxNum}
								item={items[1]}
								wasDataCached={wasDataCached}
								navigate={navigate}
								deviceType={deviceType}
								style={{
									// height: 200,
									// width: 250,
									// borderColor: '#76c5f1',
									// borderWidth: 1,
									// borderRadius: 10,
									backgroundColor: '#dd473f',
									backgroundColorMain: '#2A1E29'
									// position: 'absolute',
									// marginTop: -40,
								}}
							/>
						</View>
					</View>
				)}
				{items.length === 4 && (
					<View style={{ flexDirection: 'row', marginTop: 10 }}>
						<CategoryCard
							selectedBoxNumInfo={selectedBoxNumInfo}
							boxNumber={3}
							setBoxNum={setBoxNum}
							item={items[2]}
							wasDataCached={wasDataCached}
							navigate={navigate}
							deviceType={deviceType}
							style={{
								// height: 200,
								// width: 250,
								// borderColor: '#e46c65',
								// borderWidth: 1,
								// borderRadius: 10,
								// position: 'absolute',
								// marginTop: -40,
								backgroundColor: '#54b7ee',
								backgroundColorMain: '#1D334F'
							}}
						/>
						<CategoryCard
							selectedBoxNumInfo={selectedBoxNumInfo}
							boxNumber={4}
							setBoxNum={setBoxNum}
							item={items[3]}
							wasDataCached={wasDataCached}
							navigate={navigate}
							deviceType={deviceType}
							style={{
								// height: 200,
								// width: 250,
								// borderColor: '#d3d77f',
								// borderWidth: 1,
								// borderRadius: 10,
								backgroundColor: '#8DA53C',
								backgroundColorMain: '#27312C'
								// position: 'absolute',
								// marginTop: -40
							}}
						/>
					</View>
				)}
			</View>
		</View>
	)
}
