import { useEffect, useState } from 'react'
import { ImageBackground, Platform, Pressable, Text, View } from 'react-native'
import { InfoButton } from '../../../Components/Buttons/Button'
import { Popup } from '../../../Components/Playlists/Popup'
import useFiles from '../../../Hooks/useFiles'
import { Image } from 'react-native'
import HomeScreen from '../ContainerScreen'
import { useNavigation, useRoute } from '@react-navigation/native'
import UnderCategoryScreen from '../UnderCategoryScreen'

export default function CategoryCard({
	item,
	wasDataCached,
	navigate,
	deviceType,
	style,
	selectedBoxNumInfo,
	boxNumber,
	setBoxNum
}) {
	// const width = deviceType === 1 ? 240 : 190
	const width = 260
	const { getPhoto, isImage } = useFiles()
	// console.log(item)
	const [image, setImage] = useState(item ? item.Photo : null)
	const fallback = require('../../../assets/home-boxes.png')
	const [modalVisible, setModalVisible] = useState(false)
	const route = useRoute()
	const navigation = useNavigation()

	useEffect(() => {
		if (Platform.OS !== 'web' && wasDataCached) {
			if (isImage(item.Photo)) {
				setImage(getPhoto(item.Photo))
			} else {
				setImage(null)
			}
		}
	}, [])

	return (
		<View
			style={{
				width: width,
				borderRadius: 30,
				// marginBottom: 5,
				marginHorizontal: 5
				// overflow: 'hidden'
			}}
			onResponderRelease={() => setBoxNum(0)}
			onStartShouldSetResponder={evt => true}
			onMoveShouldSetResponder={evt => true}
		>
			<Pressable
				onPress={() => {
					// navigate('ContainerScreen', { categoryId: item.id, hasCopyPlaylistBtn: true })
					if (boxNumber === selectedBoxNumInfo) setBoxNum(0)
					else setBoxNum(boxNumber)
				}}
			>
				{/* <ImageBackground
					style={{
						width: width,
						height: width,
						borderRadius: 10,
						// opacity: boxNumber === selectedBoxNumInfo || selectedBoxNumInfo === 0 ? '100%' : '50%'
						// overflow: 'hidden'
					}}
					resizeMode="cover"
					onError={() => console.log('Error loading image...')}
					source={image ? { uri: image } : fallback}
				> */}
				<View
					style={{
						backgroundColor: style.backgroundColorMain,
						borderRadius: 25,
						// borderColor: '#003957',
						// borderWidth: 1,
						width: width,
						height: width / 1.5
					}}
				>
					{item && (
						<View
							style={{
								justifyContent: 'flex-end',
								alignItems: 'flex-end',
								marginTop: -10,
								marginRight: 10
							}}
						>
							{item.description && (
								<Pressable
									style={{
										width: 30,
										height: 30
									}}
									onPress={() => {
										setModalVisible(true)
									}}
								>
									<Image
										source={require('../../../assets/icon-png/info.png')}
										style={{
											width: 30,
											height: 30,
											marginTop: 10,
											marginRight: 10
										}}
										resizeMode={'stretch'}
									/>
								</Pressable>
							)}
						</View>
					)}
					{item && (
						<View
							style={{
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: 10,
								height: width / 1.5 - 60
							}}
						>
							{item.name && (
								<Text
									style={{
										marginLeft: 15,
										marginRight: 15,
										marginTop: 5,
										fontSize: 25,
										color: 'white'
									}}
								>
									{item.name}
								</Text>
							)}
						</View>
					)}
					<View
						style={{
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
							marginTop: -30,
							marginRight: 15
						}}
					>
						<Pressable
							style={{
								width: 30,
								height: 30
							}}
							onPress={() => {
								setModalVisible(true)
							}}
						>
							<Image
								source={require('../../../assets/icon-png/Player-Orange_icon.png')}
								style={{
									width: 30,
									height: 30,
									marginTop: 10,
									marginRight: 10
								}}
								resizeMode={'stretch'}
							/>
						</Pressable>
					</View>
				</View>
				{/* </ImageBackground> */}

				<View>
					{/* {boxNumber === selectedBoxNumInfo && ( */}
					<View style={{ marginTop: style.marginTop }}>
						<UnderCategoryScreen
							route={route}
							navigation={navigation}
							categoryIdParam={item ? item.id : null}
							isThumbnailsSize={true}
							style={style}
						/>
					</View>
					{/* )} */}
				</View>
			</Pressable>
			{modalVisible && (
				<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={item.description} />
			)}
		</View>
	)
}
