import { useEffect, useState } from 'react'
import { ImageBackground, Platform, Pressable, Text, View } from 'react-native'
import { Popup } from '../../../Components/Playlists/Popup'
import useFiles from '../../../Hooks/useFiles'

export default function UnderCategoryCard({
	item,
	wasDataCached,
	navigate,
	deviceType,
	screenName,
	params,
	isThumbnailsSize,
	isSubContainer = false,
	style
}) {
	const width = isThumbnailsSize ? 80 : deviceType === 1 ? 240 : 190

	const { getPhoto, isImage } = useFiles()
	let photo = item.container_group_photo ? item.container_group_photo : item.sub_container_photo
	const [image, setImage] = useState(photo)
	const fallback = require('../../../assets/fallback_cat.png')
	const [modalVisible, setModalVisible] = useState(false)
	let description = item.container_group_description ? item.container_group_description : item.sub_container_description
	let name = item.container_group_name ? item.container_group_name : item.sub_container_name
	useEffect(() => {
		if (Platform.OS !== 'web' && wasDataCached) {
			if (isImage(photo)) {
				setImage(getPhoto(photo))
			} else {
				setImage(null)
			}
		} else {
			setImage(photo)
		}
	}, [])

	return (
		<Pressable onPress={() => navigate(screenName, params)}>
			<View
				style={{
					width: width,
					height: width / 1.5,
					borderRadius: 5,
					// marginBottom: 15,
					// marginHorizontal: isThumbnailsSize ? 0 : 15,
					// overflow: 'hidden',
					// flexDirection: isThumbnailsSize ? 'row' : undefined,
					backgroundColor: style.backgroundColor,
				}}
			>
				{/* <ImageBackground
					style={{ width: width, height: width }}
					imageStyle={{ borderRadius: 5, borderWidth: 1, borderColor: '#C1C1C1' }}
					resizeMode="stretch"
					onError={() => console.log('Error loading image...')}
					source={image ? { uri: image } : fallback}
				> */}
				{(isSubContainer || isThumbnailsSize) && (
					<View
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							height: width / 1.5
							// width: width - 10,
							// marginLeft: isThumbnailsSize ? undefined : 15
							// marginRight: isThumbnailsSize ? 5 : 15
						}}
					>
						<Text
							style={{
								// marginTop: 5,
								fontSize: isThumbnailsSize ? 11 : 25,
								color: 'white',
								fontSize: 13,
								marginLeft: 12,
								marginRight: 12
							}}
							ellipsizeMode={'tail'}
							numberOfLines={3}
						>
							{name}
						</Text>
					</View>
				)}
				{/* </ImageBackground> */}
			</View>
			{/* {modalVisible && (
				<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={description} />
			)} */}
		</Pressable>
	)
}
