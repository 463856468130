import Checkbox from 'expo-checkbox'
import React, { useEffect, useState } from 'react'
import { Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'

export const CheckboxTicker = ({ isChecked, label, handleCheckboxClick, paragraphHeight = 30, checkboxMargin = 8, item = undefined, style }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width < 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width < 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	useEffect(() => {
		handleResize()
	}, [width, height])

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			width: '100%'
			// marginHorizontal: 16,
			// marginVertical: 32
		},
		section: {
			flexDirection: 'row',
			alignItems: 'center',
			width: '100%'
		},
		paragraph: {
			color: 'white',
			fontSize: 16,
			height: paragraphHeight,
			marginTop: 10
		},
		checkbox: {
			margin: checkboxMargin
		}
	})
	
	isChecked = item ? item.isChecked : isChecked

	const handleCheckbox = value => {
		if (!item) handleCheckboxClick(!value) // value is boolean
		else handleCheckboxClick(item, !value)
	}

	return (
		<View style={styles.container}>
			<View style={styles.section}>
				<Checkbox
					style={styles.checkbox}
					value={isChecked}
					color={isChecked ? colors.accent : undefined}
					onValueChange={handleCheckbox}
				/>
				<Pressable onPress={() => handleCheckbox(!isChecked)}>
					<Text style={[styles.paragraph, style]}>{label}</Text>
				</Pressable>
			</View>
		</View>
	)
}
