import React from 'react'
import { Text, View } from 'react-native'
import { MenuDivider } from 'react-native-material-menu'
import { colors } from '../../Helpers/variables'
import Constants from 'expo-constants'

export default function Footer() {
	return (
		<View
			style={{
				backgroundColor: colors.boxes,
				width: '100%',
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0
			}}
		>
			<View
				style={{
					marginLeft: 80,
					marginRight: 80,
					backgroundColor: colors.boxes,
					width: '85%'
				}}
			>
				<MenuDivider color="gray" />
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 30, marginBottom: 8 }}>
					<Text style={{ color: '#545C6D', fontSize: 16 }}>© MusicMind 2024</Text>
					<Text style={{ color: '#545C6D', fontSize: 16 }}>Version {Constants.expoConfig.version}</Text>
				</View>
			</View>
		</View>
	)
}
