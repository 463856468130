// import RNPickerSelect from 'react-native-picker-select'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import SelectDropdown from 'react-native-select-dropdown'
import uuid from 'react-native-uuid'
import { colors } from '../../Helpers/variables'

export const Select = ({ items, onValueChange, style, width = 230, customInput2Width = 230, customMarginLeft = 5, value, ...props }) => {
	const [border, setBorder] = useState(colors.accent)
	const pickerStyle = {
		input: {
			color: 'white',
			borderColor: 'white',
			backgroundColor: colors.secondary,
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: width
		},
		input2: {
			color: 'white',
			borderColor: 'white',
			backgroundColor: colors.secondary,
			borderWidth: 2,
			borderRadius: 5,
			minWidth: 45,
			width: customInput2Width,
			// height: 300,
			overflowY: 'auto'
		}
	}

	const [itemsSelect, setItemsSelect] = useState([])
	const [selectedIndex, setSelectedIndex] = useState(undefined)

	useEffect(() => {
		let newItems = []
		items.map(item => {
			newItems.push(item.label)
		})
		setItemsSelect(newItems)
		setSelectedIndex(0)
	}, [items])

	useEffect(() => {
		setSelectedIndex(items.findIndex(x => x.value === value))
	}, [value])

	return (
		<View style={{ marginLeft: customMarginLeft }}>
			<SelectDropdown
				data={itemsSelect}
				onSelect={(selectedItem, index) => {
					onValueChange(items[index].value)
				}}
				dropdownStyle={pickerStyle.input2}
				buttonStyle={pickerStyle.input}
				rowTextStyle={{ color: 'white', textAlign: 'left', fontSize: 15 }}
				buttonTextStyle={{ color: 'white', textAlign: 'left', fontSize: 15 }}
				defaultValueByIndex={selectedIndex}
				key={uuid.v4()}
			/>
		</View>
	)
}
