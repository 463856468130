import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import Constants from 'expo-constants'
import { Image, Platform, Pressable, Text, useWindowDimensions, View } from 'react-native'

import { useEffect, useState } from 'react'
import useLayout from '../../../Hooks/useLayout'

// For Step by Step Walkthrough
import { CopilotStep, useCopilot, walkthroughable } from 'react-native-copilot'
import { TourModal } from '../../Modals/TourModal'

// import LinkButton from './components/LinkButton'
import PlayButton from './components/TrackControls/PlayButton'
import TrackChangeButton from './components/TrackControls/TrackChangeButton'

import TermsConditionsModal from '../../Modals/TermsConditionsModal'
import TrackControls from './components/TrackControls'
import useSound from '../../../Hooks/useSound'
import { colors } from '../../../Helpers/variables'
import AnnouncementsModal from '../../Modals/AnnouncementsModal'
import { TouchableHighlight } from 'react-native'
import NewsFeedMenu from '../NewsFeedMenu'
import { useNavigation } from '@react-navigation/native'
const WalkthroughableView = walkthroughable(View)

const Sidebar = props => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const navigation = useNavigation()

	const {
		handleTrackPlayer,
		handleClickShuffle,
		isShuffleActive,
		handleClickRepeat,
		repeatType,
		mediaPlayerAcquisition,
		currentPlayingTrack,
		setIsShuffleActive
	} = useSound()

	const { isSidebarOpen, toggleSidebar, deviceType, setIsShowHeaderTour } = useLayout()
	const [tourModal, setTourModal] = useState({
		title: undefined,
		description: undefined
	})
	const { copilotEvents } = useCopilot()

	const handleSearch = async (text, categoryFilters) => {
		setIsShowSearchBox(true)
		navigation.navigate('SearchScreen', { searchInput: text, categoryFilters: categoryFilters })
	}

	useEffect(() => {
		//setting a function to handle the step change event
		copilotEvents.on('stepChange', handleStepChange)
		copilotEvents.on('stop', handleStop)

		return () => {
			copilotEvents.off('stop')
			copilotEvents.off('stepChange')
			copilotEvents.off('start')
		}
	}, [])

	const LinkButton = ({ route, routeParams, title, icon, open, customOnPress = undefined, ...props }) => {
		return (
			<TouchableHighlight
				onPress={() => {
					if (customOnPress) customOnPress()
					else navigation.navigate(route, routeParams)
				}}
				style={{ marginTop: 5, width: 200 }}
				{...props}
			>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{icon.length < 20 ? (
						<Ionicons name={icon} size={deviceType === 1 ? 30 : 24} color={'#ffffff'}
							style={{ marginLeft: isWideScreen ? 10 : 7, marginRight: 7 }} />
					) : (
						<Image
							source={icon}
							style={{
								width: 40,
								height: 40
							}}
							resizeMode={'stretch'}
						/>
					)}
					{open && (
						<Text style={{ fontSize: Platform.OS === 'android' ? 20 : 16, color: '#ffffff', marginLeft: 5 }}>
							{title}
						</Text>
					)}
				</View>
			</TouchableHighlight>
		)
	}
	const handleStepChange = step => {
		//Handler, in case we want to handle the step change
		console.log(`Current step is: ${step.order}`)
	}

	const handleStop = (isSkipTour = false) => {
		if (!isSkipTour) setIsShowHeaderTour(true)
		props.stop
	}

	return (
		<View
			style={{
				flex: 1,
				//justifyContent: 'space-between',
				//alignItems: 'flex-start'
			}}
		>
			<View style={{ paddingHorizontal: 5, marginBottom: 5, marginLeft: 5 }}>
				{deviceType !== 1 ? (
					<Image
						source={require('../../../assets/icon-png/MusicMind_logo.png')}
						style={{
							width: 150,
							height: 60
						}}
						resizeMode={'stretch'}
					/>
				) : (
					<Pressable onPress={toggleSidebar} style={{ marginTop: 10 }}>
						<Ionicons name={'ios-menu'} size={30} color={'#ffffff'} />
					</Pressable>
				)}
			</View>
			<View style={{
				//width: '100%',
				//alignItems: isWideScreen || isSidebarOpen ? 'flex-start' : 'center',
				//paddingHorizontal: !isWideScreen && isSidebarOpen ? 10 : (isWideScreen ? 25 : 0),
				backgroundColor: colors.boxes,
				paddingTop: 15,
				marginRight: 10,
				marginTop: -10,
				// height: '92%'
			}}
			>
				<View style={{ paddingHorizontal: 5, paddingLeft: 10, marginLeft: 10 }}>
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						<LinkButton
							open={isSidebarOpen}
							route="CategoryScreen"
							title={'Hjem'}
							icon={require('../../../assets/icon-png/home.png')}
						/>
						<Pressable
							onPress={() => {
								setShouldReset(true)
							}}
							style={{ width: 40 }}
						>
							<Image
								source={require('../../../assets/icon-png/refresh.png')}
								style={{
									width: 40,
									height: 40,
									marginTop: 8
								}}
								resizeMode={'stretch'}
							/>
						</Pressable>
					</View>
					<LinkButton
						open={isSidebarOpen}
						route="SearchScreen"
						title={'Søg musik'}
						icon={require('../../../assets/icon-png/search.png')}
						customOnPress={handleSearch}
					/>
					<LinkButton
						open={isSidebarOpen}
						route="InternalContainerScreen"
						title={'Internt Miljø'}
						icon={require('../../../assets/icon-png/internal-playlist.png')}
					/>
					<LinkButton
						open={isSidebarOpen}
						route="MyPlaylistsScreen"
						title={'Mine spillelister'}
						icon={require('../../../assets/icon-png/my-playlist.png')}
					/>
					<LinkButton
						open={isSidebarOpen}
						route="CreatePlaylistScreen"
						title={'Opret Spilleliste'}
						icon={require('../../../assets/icon-png/create-playlist.png')}
					/>
					<LinkButton
						open={isSidebarOpen}
						route="ImportFromThirdPartyModal"
						title={'Impoter Spilleliste'}
						icon={'cloud-upload-sharp'}
						isModal={true}
					/>
					<LinkButton
						open={isSidebarOpen}
						route=""
						title={'Det personlige MusicMind'}
						icon={require('../../../assets/icon-png/profile.png')}
					/>
				</View>
				{/* <NewsFeedMenu open={isSidebarOpen} route="" title={'Udforsk nyheder'} /> */}
				{deviceType !== 1 || (!isWideScreen && isSidebarOpen) ? (
					<TrackControls CopilotStep={CopilotStep} WalkthroughableView={WalkthroughableView} />
				) : (
					<View
						style={{
							//width: '100%',
							alignItems: 'center',
							justifyContent: 'space-between',
							flexDirection: isSidebarOpen ? 'row' : 'column',
							paddingHorizontal: isWideScreen ? 25 : 0,
							paddingVertical: 10
						}}
					>
						<Pressable disabled={currentPlayingTrack === null}>
							<Ionicons
								name="shuffle"
								size={isWideScreen ? 26 : 22}
								color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
								style={{ marginHorizontal: 10 }}
								onPress={() => handleClickShuffle()}
							/>
						</Pressable>
						<TrackChangeButton size={isWideScreen ? 30 : 22} type={'prev'} />
						<PlayButton size={isWideScreen ? 55 : 30} />
						<TrackChangeButton size={isWideScreen ? 30 : 22} type={'next'} />
						<Pressable disabled={currentPlayingTrack === null}>
							{repeatType === 'one' ? (
								<MaterialIcons
									name="repeat-one"
									size={isWideScreen ? 26 : 22}
									color={currentPlayingTrack === null ? colors.secondary : colors.accent}
									// style={{ marginHorizontal: 10 }}
									onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
								/>
							) : (
								<Ionicons
									name="ios-repeat-sharp"
									size={isWideScreen ? 26 : 22}
									color={currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'}
									// style={{ marginHorizontal: 10 }}
									onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
								/>
							)}
						</Pressable>
					</View>
				)}

			</View>

			{props.isTourModalVisible && (
				<TourModal
					title={tourModal.title}
					description={tourModal.description}
					visible={props.isTourModalVisible}
					skipTour={handleStop}
					startTour={props.start}
					setTourModal={props.handleCancelGuidedTour}
				/>
			)}

			{props.isTermsConditionsModalVisible && (
				<TermsConditionsModal
					visible={props.isTermsConditionsModalVisible}
					handleAgreeTermsConditions={props.handleAgreeTermsConditions}
					handleCancelTermsConditions={props.handleCancelTermsConditions}
				/>
			)}
			{props.isAnnouncementsModalVisible && (
				<AnnouncementsModal
					visible={props.isAnnouncementsModalVisible}
					handleAgreeAnnouncements={props.handleAgreeAnnouncements}
				/>
			)}
		</View>
	)
}

export default Sidebar
