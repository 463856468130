import React, { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import { colors } from '../../Helpers/variables'
import CarouselCardItem from './CarouselCardItem'

const CarouselCards = ({ data }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width < 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width < 320)
	}

	return (
		<View style={{ width: '100%', marginVertical: 5,  height: '85%' }}>
			<FlatGrid
				itemDimension={isWideScreen ? 400 : 200}
				data={data}
				renderItem={({ item }) => <CarouselCardItem item={item} />}
				ListEmptyComponent={
					<View>
						<Text style={{ color: 'white', marginTop: isWideScreen ? 30 : 20, fontSize: isWideScreen ? 20 : 16 }}>Ingen data</Text>
					</View>
				}
				keyExtractor={item => item.id}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingBottom: isWideScreen ? 20 : 10 }}
				showsVerticalScrollIndicator={true}
				showsHorizontalScrollIndicator={true}
				horizontal={true}
			/>
		</View>
	)
}

export default CarouselCards
