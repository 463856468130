/* ----------------------------- Library imports ---------------------------- */
import { useEffect, useState } from 'react'
import { Platform, ScrollView, Text, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'

/* --------------------------- Component Imports ---------------------------- */
import Loader from '../../Components/Loader/Loader'

/* ------------------------------ Hooks imports ----------------------------- */
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'

/* ------------------------------ Data helpers ------------------------------ */
// import { addDataToSubContainersTable, retrieveDataFromSubContainersTable } from '../../Helpers/database/subContainersTable'
import { SUB_CONTAINER_BOXES_URL, colors } from '../../Helpers/variables'

/* ---------------------------- Exception handler --------------------------- */
import RefreshButton from '../../Components/RefreshButton'
import { captureException } from '../../Helpers/sentryLog'
import { isCachedDataValid } from '../../Helpers/timeConverter'
import ContainerCard from './components/ContainerCard'
import { playlistSortByPosition } from '../../Helpers/sortArray'

export default function SubContainerScreen({ route, navigation }) {
	const api = useAxios()

	const { deviceType, isOffline, shouldReset, setShouldReset } = useLayout()

	const [subContainers, setSubContainers] = useState([])
	// const [originalSubContainers, setOriginalSubContainers] = useState([])
	const [loading, setLoading] = useState(true)
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)

	const getSubContainers = mainSubs => {
		let newContainers = []
		mainSubs.map(async item => {
			let url =
				SUB_CONTAINER_BOXES_URL +
				'?level=' +
				(item.level + 1) +
				'&container=' +
				item.container_group +
				'&category=' +
				item.category_group

			url = url + '&main_sub_container=' + item.sub_container

			let { data } = await api.get(url)

			if (data.length > 0) item.hasNextSubContainer = true
			else item.hasNextSubContainer = false

			newContainers.push(item)

			if (newContainers.length === mainSubs.length) {
				newContainers = playlistSortByPosition(newContainers)
				setSubContainers(newContainers)
				setLoading(false)
				// setOriginalSubContainers(data)
				// addDataToSubContainersTable(data)
			}
		})
	}

	const getDataFromApi = async () => {
		setLoading(true)
		const routeParams = route.params ? route.params : global.routeParams
		let url =
			SUB_CONTAINER_BOXES_URL +
			'?level=' +
			routeParams.level +
			'&container=' +
			routeParams.containerId +
			'&category=' +
			routeParams.categoryId

		if (routeParams.level !== 1) url = url + '&main_sub_container=' + routeParams.main_sub_container

		const { data } = await api.get(url)
		if (data.length === 0) {
			setSubContainers(data)
			setLoading(false)
		} else {
			await getSubContainers(data)
		}
	}

	useEffect(() => {
		setError(false)
		// setLoading(true)
		async function boot() {
			try {
				// if (Platform.OS === 'web') {
				// 	await getDataFromApi()
				// 	return
				// }

				await getDataFromApi()
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			} finally {
				// setLoading(false)s
				setShouldReset(false)
			}
		}

		boot()
	}, [route.params ? route.params.level : global.routeParams.level])

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			setLoading(true)
			async function boot() {
				try {
					if (Platform.OS === 'web') {
						await getDataFromApi()
						return
					}

					await getDataFromApi()
				} catch (error) {
					console.error(error)
					captureException(error)
					setError(true)
				} finally {
					setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}
				await getDataFromApi()
				// const cachedData = await retrieveDataFromSubContainersTable()
				// if (!isCachedDataValid(cachedData)) {
				// 	await getDataFromApi()
				// } else {
				// 	setWasDataCached(true)
				// 	setSubContainers(cachedData)
				// 	setOriginalSubContainers(cachedData)
				// }
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		boot()
	}, [shouldRefresh])

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.boxes, flex: 1, marginBottom: 10, padding: 7 }}>
			<ScrollView>
				<FlatGrid
					data={subContainers}
					renderItem={({ item }) => (
						<ContainerCard
							wasDataCached={wasDataCached}
							item={item}
							navigate={item.hasNextSubContainer ? navigation.push : navigation.navigate}
							deviceType={deviceType}
							screenName={item.hasNextSubContainer ? 'SubContainerScreen' : 'PlaylistScreen'}
							params={
								item.hasNextSubContainer
									? {
											categoryId: item.category_group,
											containerId: item.container_group,
											level: parseInt(item.level) + 1,
											main_sub_container: item.sub_container
									  }
									: {
											categoryId: item.container_group,
											hasCopyPlaylistBtn: true,
											sub_container: item.id
									  }
							}
							isSubContainer={true}
						/>
					)}
					ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen data...</Text>}
					keyExtractor={item => item.id}
					itemDimension={200}
					// additionalRowStyle={{ padding: 0 }}
					contentContainerStyle={{ paddingBottom: 90 }}
					style={{ flex: 1 }}
				/>
			</ScrollView>
		</View>
	)
}
