import React, { useEffect, useState } from 'react'
import { Platform, ScrollView } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import PlaylistView from '../../Components/Playlists/PlaylistView'
import RefreshButton from '../../Components/RefreshButton'
import { addDataToPlaylistsTable, retrieveDataFromPlaylistsTable } from '../../Helpers/database/playlistsTable'
import { captureException } from '../../Helpers/sentryLog'
import { isCachedDataValid } from '../../Helpers/timeConverter'
import { colors, PLAYLIST_GROUP_URL, SUB_CONTAINER_PLAYLIST_GROUP_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'
import WhatsNewScreen from '../WhatsNewScreen/WhatsNewScreen'
import { View } from 'react-native'

export default function PlaylistScreen({ route, navigation }) {
	const api = useAxios()
	const [loading, setLoading] = useState(true)
	const [playlists, setPlaylists] = useState([])
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)

	const { isOffline, deviceType, shouldReset, setShouldReset } = useLayout()
	const categoryId = route.params ? route.params.categoryId : global.routeParams.categoryId
	const categoryName = route.params ? route.params.categoryName : global.routeParams.categoryName
	const hasCopyPlaylistBtn = route.params ? route.params.hasCopyPlaylistBtn : global.routeParams.hasCopyPlaylistBtn

	const getDataFromApi = async () => {
		let url = ''
		if (route.params.sub_container) url = SUB_CONTAINER_PLAYLIST_GROUP_URL + route.params.sub_container
		let { data } = await api.get(url)
		if (data.length === 0) {
			url = PLAYLIST_GROUP_URL + categoryId
			let { data } = await api.get(url)
			setPlaylists(data)
		} else setPlaylists(data)
		addDataToPlaylistsTable(data)
	}

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			setLoading(true)

			async function boot() {
				try {
					if (Platform.OS === 'web' || !isOffline) {
						await getDataFromApi()
						return
					}

					await getDataFromApi()
				} catch (error) {
					captureException(error)
					setError(true)
				} finally {
					setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)

		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}

				const cachedData = await retrieveDataFromPlaylistsTable(categoryId)
				if (!isCachedDataValid(cachedData)) {
					await getDataFromApi()
					setShouldReset(false)
				} else {
					setWasDataCached(true)
					setPlaylists(cachedData)
				}
			} catch (error) {
				captureException(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		boot()
	}, [shouldRefresh, categoryId])

	const handlePlaylistPress = (
		playlistId,
		photo,
		title,
		musicTherapistName,
		musicTherapistId
	) => {
		navigation.navigate('TracksScreen', {
			playlistId,
			photo,
			title,
			isTracksRemovable: false,
			hasCopyPlaylistBtn: hasCopyPlaylistBtn,
			musicTherapistName: musicTherapistName,
			musicTherapistId: musicTherapistId
		})
	}

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.boxes, flex: 1 }}>
			<ScrollView>
				<WhatsNewScreen deviceType={deviceType} type={'playlistgroups'} navigation={navigation} id={categoryId} handlePlaylistPress={handlePlaylistPress} />
				<PlaylistView
					setShouldReset={setShouldReset}
					wasDataCached={wasDataCached}
					playlists={playlists}
					handlePlaylistPress={handlePlaylistPress}
					categoryName={categoryName}
				/>
			</ScrollView>
		</View>
	)
}
